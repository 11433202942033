import React from "react";

function Aboutpage() {
    return (
        <div>
        <div>
            <h1>Find Out More Shortly</h1>
        </div>
        </div>
    )
}
export default Aboutpage